import { Switch, Route } from 'react-router-dom'
import { AuthenticatedTemplate } from '@azure/msal-react'
import GlobalInputs from './components/global-inputs/GlobalInputsContainer'
import Landing from './components/landing/Landing'
import Expenses from './components/expenses/Expenses'
import Resources from './components/resources/ResourcesContainer'
import Scenarios from './components/scenarios/Scenarios'
import Summary from './components/summary/Summary'
import AdminDashboard from './components/admin-dashboard/AdminDashboard'

const Router = () => {
  return (
    <Switch>
      <Route exact path='/pricing-plan/:pricingPlanId/global-input'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <GlobalInputs />}
        <AuthenticatedTemplate>
          <GlobalInputs />
        </AuthenticatedTemplate>
      </Route>
      <Route exact path='/pricing-plan/:pricingPlanId/resources'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <Resources />}
        <AuthenticatedTemplate>
          <Resources />
        </AuthenticatedTemplate>
      </Route>
      <Route path='/pricing-plan/:pricingPlanId/expenses'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <Expenses />}
        <AuthenticatedTemplate>
          <Expenses />
        </AuthenticatedTemplate>
      </Route>
      <Route path='/pricing-plan/:pricingPlanId/scenarios'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <Scenarios />}
        <AuthenticatedTemplate>
          <Scenarios />
        </AuthenticatedTemplate>
      </Route>
      <Route path='/pricing-plan/:pricingPlanId/summary'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <Summary />}
        <AuthenticatedTemplate>
          <Summary />
        </AuthenticatedTemplate>
      </Route>
      <Route path='/admin-dashboard'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <AdminDashboard />}
        <AuthenticatedTemplate>
          <AdminDashboard />
        </AuthenticatedTemplate>
      </Route>
      <Route path='/'>
        {window.location.href.includes('react-cct-test-ky.azurewebsites.net') && <Landing />}
        <AuthenticatedTemplate>
          <Landing />
        </AuthenticatedTemplate>
      </Route>
    </Switch>
  )
}

export default Router
