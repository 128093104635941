import React, { ReactElement, useContext } from 'react'
import './LandingTable.css'
import YellowTriangle from '../../assets/yellow_triangle.svg'
import WhiteTriangle from '../../assets/white_triangle.svg'
import NextIcon from '../../assets/next_icon.svg'
import PrevIcon from '../../assets/prev_icon.svg'
import { useEffect, useState, useMemo } from 'react'
import DeleteModal from '../modals/DeleteModal'
import { Worksheet } from './Worksheet'
import { useHistory } from 'react-router-dom'
import Tooltip from '../common/Tooltip'
import { UserContext } from '../../App'
import { CheckinCheckout, duplicatePricingPlanData } from '../utils/fetchHandler'
import DelegateModal from '../modals/DelegateModal'
import { boolean } from 'yup'

interface LandingTableProps {
  pricingPlans: any
  confirmedUsers:any
}

const LandingTable = (props: LandingTableProps): ReactElement => {
  const { pricingPlans } = props
  const [worksheets, setWorksheets] = useState<Worksheet[]>([])
  const [numPages, setNumPages] = useState(1)
  const [deleteId, setDeleteId] = useState('') // ID of worksheet to be deleted
  const [deleteName, setDeleteName] = useState('') // Worksheet name of row to be deleted
  const [currentPage, setCurrentPage] = useState(1)
  const [hasFetchedWorksheets, setHasFetchedWorksheets] = useState(false)
  const [triangle, setTriangle] = useState([false, false, false, false, false])
  const [isAscending, setIsAscending] = useState([true, true, true, true, true]) // True if original sort and False if reverse sort
  const history = useHistory()
  const userId = useContext(UserContext)[1]
  const [currentColumn, setCurrentColumn] = useState<number>()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [delegateId, setDelegateId] = useState('') // ID of worksheet to be delegated
  const {confirmedUsers}= props
  const [ischeckin, setIsCheckedIn] = useState(false);
  const [isDelegated, setIsDelegated] = useState(false);
  const [delegateAccess, setIsDelegateAccess] = useState(false);
  const [planSetatus, setPlanStatus] = useState('')
  const [status, setStatus] = useState<boolean>()
  const [msg, setMsg] = useState('')
  const [showMsg, setShowMsg] = useState(false);
  const [msgTimeout, setMsgTimeout] = useState<number | null>(null);
  const [Index, setWorksheetIndex] = useState('')

  useEffect(() => {
    if (pricingPlans !== undefined) {
      initialSetup()
    }
  }, [userId, pricingPlans])

  const initialSetup = async () => {
    try {
      if (userId.length > 1) {
        const allWorksheets = pricingPlans
        // Format the dates to be displayed in the table
        for (let i = 0; i < allWorksheets.length; i++) {
          const created = allWorksheets[i].created
          const newCreated = formatDate(created)
          allWorksheets[i].created = newCreated
        }

        for (let i = 0; i < allWorksheets.length; i++) {
          const startDate = formatDate(allWorksheets[i].dealStartDate)
          const endDate = formatDate(allWorksheets[i].dealEndDate)

          allWorksheets[i].dealStartDate = startDate
          allWorksheets[i].dealEndDate = endDate
        }

        setWorksheets(allWorksheets)
        setHasFetchedWorksheets(true)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (hasFetchedWorksheets) {
      // Sort table by Created and Worksheet Name
      const initialNameSorted = [...worksheets].sort((a: Worksheet, b: Worksheet) => {
        let aUppercaseName = a.worksheetName.toUpperCase()
        let bUppercaseName = b.worksheetName.toUpperCase()
        return bUppercaseName > aUppercaseName ? -1 : 1
      })

      const initialCreatedSorted = [...initialNameSorted].sort(function (a: Worksheet, b: Worksheet): number {
        let aDate = a.created.split('/')
        let aNewYear = '20' + aDate[2]

        let bDate = b.created.split('/')
        let bNewYear = '20' + bDate[2]

        return (
          new Date(parseInt(bNewYear), parseInt(bDate[1]), parseInt(bDate[0])).valueOf() -
          new Date(parseInt(aNewYear), parseInt(aDate[1]), parseInt(aDate[0])).valueOf()
        )
      })

      setWorksheets(initialCreatedSorted)
      setNumPages(Math.ceil(worksheets.length / 10))
    }
  }, [hasFetchedWorksheets])

  // Converts from UTC date format to desired table format (DD/MM/YY)
  const formatDate = (date: string) => {
    let dateArray = date.split('-') // Split into YYYY MM DD
    let year = dateArray[0].slice(2, 4) // Get year in YY format
    let month = dateArray[1]
    let dayArray = dateArray[2].split('T')
    let day = dayArray[0]
    const newDate = day + '/' + month + '/' + year
    return newDate
  }

  const sortByWorksheetName = () => {
    const sortedArray = [...worksheets].sort(function (a: Worksheet, b: Worksheet): number {
      let aUppercaseName = a.worksheetName.toUpperCase()
      let bUppercaseName = b.worksheetName.toUpperCase()
      if (isAscending[0]) {
        return bUppercaseName > aUppercaseName ? -1 : 1
      } else {
        return bUppercaseName < aUppercaseName ? -1 : 1
      }
    })
    setWorksheets(sortedArray)
  }

  const sortByEngagementName = () => {
    sortByWorksheetName()
    sortByCreated()
    const sortedArray = [...worksheets].sort(function (a: Worksheet, b: Worksheet): number {
      let aUppercaseName = a.engagementName.toUpperCase()
      let bUppercaseName = b.engagementName.toUpperCase()
      if (isAscending[1]) {
        return bUppercaseName > aUppercaseName ? -1 : 1
      } else {
        return bUppercaseName < aUppercaseName ? -1 : 1
      }
    })
    setWorksheets(sortedArray)
  }

  const sortByClient = () => {
    sortByWorksheetName()
    sortByCreated()
    const sortedArray = [...worksheets].sort(function (a: Worksheet, b: Worksheet): number {
      let aUppercaseName = a.client.toUpperCase()
      let bUppercaseName = b.client.toUpperCase()
      if (isAscending[2]) {
        return bUppercaseName > aUppercaseName ? -1 : 1
      } else {
        return bUppercaseName < aUppercaseName ? -1 : 1
      }
    })
    setWorksheets(sortedArray)
  }

  const sortByEngagementDate = () => {
    sortByWorksheetName()
    sortByCreated()
    const sortedArray = [...worksheets].sort(function (a: Worksheet, b: Worksheet): number {
      const aEngagementDate = a.dealStartDate + ' - ' + a.dealEndDate
      let aRange = aEngagementDate.split(' - ')
      let aDateStart = aRange[0].split('/')
      let aDateEnd = aRange[1].split('/')
      let aStartNewYear = '20' + aDateStart[2]
      let aEndNewYear = '20' + aDateEnd[2]

      const bEngagementDate = b.dealStartDate + ' - ' + b.dealEndDate
      let bRange = bEngagementDate.split(' - ')
      let bDateStart = bRange[0].split('/')
      let bDateEnd = bRange[1].split('/')
      let bStartNewYear = '20' + bDateStart[2]
      let bEndNewYear = '20' + bDateEnd[2]

      if (isAscending[3]) {
        let diff =
          new Date(parseInt(bStartNewYear), parseInt(bDateStart[1]), parseInt(bDateStart[0])).valueOf() -
          new Date(parseInt(aStartNewYear), parseInt(aDateStart[1]), parseInt(aDateStart[0])).valueOf()
        if (diff === 0) {
          // Compare end dates if start dates are the same
          return (
            new Date(parseInt(bEndNewYear), parseInt(bDateEnd[1]), parseInt(bDateEnd[0])).valueOf() -
            new Date(parseInt(aEndNewYear), parseInt(aDateEnd[1]), parseInt(aDateEnd[0])).valueOf()
          )
        } else {
          return diff
        }
      } else {
        let diff =
          new Date(parseInt(aStartNewYear), parseInt(aDateStart[1]), parseInt(aDateStart[0])).valueOf() -
          new Date(parseInt(bStartNewYear), parseInt(bDateStart[1]), parseInt(bDateStart[0])).valueOf()
        if (diff === 0) {
          // Compare end dates if start dates are the same
          return (
            new Date(parseInt(aEndNewYear), parseInt(aDateEnd[1]), parseInt(aDateEnd[0])).valueOf() -
            new Date(parseInt(bEndNewYear), parseInt(bDateEnd[1]), parseInt(bDateEnd[0])).valueOf()
          )
        } else {
          return diff
        }
      }
    })
    setWorksheets(sortedArray)
  }

  const sortByCreated = () => {
    sortByWorksheetName()
    const sortedArray = [...worksheets].sort(function (a: Worksheet, b: Worksheet): number {
      let aDate = a.created.split('/')
      let aNewYear = '20' + aDate[2]

      let bDate = b.created.split('/')
      let bNewYear = '20' + bDate[2]

      if (isAscending[4]) {
        return (
          new Date(parseInt(bNewYear), parseInt(bDate[1]), parseInt(bDate[0])).valueOf() -
          new Date(parseInt(aNewYear), parseInt(aDate[1]), parseInt(aDate[0])).valueOf()
        )
      } else {
        return (
          new Date(parseInt(aNewYear), parseInt(aDate[1]), parseInt(aDate[0])).valueOf() -
          new Date(parseInt(bNewYear), parseInt(bDate[1]), parseInt(bDate[0])).valueOf()
        )
      }
    })
    setWorksheets(sortedArray)
  }

  const headingSelected = (columnNum: number) => {
    // Set state to decide if the column is meant to be updated to ascending or descending order
    // If the new column is different to the previous column selection, reset it to false
    const prevSelectedColumn = currentColumn
    const newIsAscending = [...isAscending]
    if (prevSelectedColumn !== columnNum && prevSelectedColumn !== undefined) {
      newIsAscending[prevSelectedColumn] = true
    }
    newIsAscending[columnNum] = !isAscending[columnNum]
    setIsAscending(newIsAscending)
    setCurrentColumn(columnNum)

    // UPpdate styling of the column header and triangle symbol
    const headerclass = document.getElementsByClassName('landing-table-heading')
    const headings = headerclass[0].getElementsByTagName('th')
    const triangleObjects = Array.from(
      document.getElementsByClassName('landing-table-triangle') as HTMLCollectionOf<HTMLElement>
    )
    headings[columnNum].style.color = '#ffe600'

    let newTriangle = [...triangle]
    for (let i = 0; i < triangle.length; i++) {
      if (i === columnNum) {
        newTriangle[i] = true
        headings[columnNum].style.borderBottom = '3px solid #ffe600'
        if (isAscending[columnNum]) {
          // Ascending (arrow points up)
          triangleObjects[columnNum].style.transform = 'rotateX(180deg) translateY(1px)'
        } else {
          // Descending (arrow points down)
          triangleObjects[columnNum].style.transform = 'rotateX(0deg) translateY(1px)'
        }
      } else {
        newTriangle[i] = false
        headings[i].style.color = '#ffffff'
        headings[i].style.borderBottom = 'none'
        triangleObjects[i].style.transform = 'rotateX(0deg)'
      }
    }
    setTriangle(newTriangle)

    // Update sorting of the column
    if (columnNum === 0) {
      sortByWorksheetName()
    } else if (columnNum === 1) {
      sortByEngagementName()
    } else if (columnNum === 2) {
      sortByClient()
    } else if (columnNum === 3) {
      sortByEngagementDate()
    } else if (columnNum === 4) {
      sortByCreated()
    }
  }

  const showDeletePopup = (id: string, name: string) => {
    const modal = document.getElementById('delete-modal') as HTMLElement
    modal.style.display = 'block'
    setDeleteId(id)
    setDeleteName(name)
  }

  const showDelegatePopup = (id: string,checkin:boolean,isDelegated:boolean,delegateAccess:boolean,planStatus:string) => {
    const modal = document.getElementById('delegate-modal') as HTMLElement
    modal.style.display = 'block'
    setDelegateId(id)
    setIsCheckedIn(checkin)
    setIsDelegated(isDelegated)
    setIsDelegateAccess(delegateAccess)
    setPlanStatus(planStatus)
    console.log(delegateAccess)
  }
  const hideDelegatePopup = () => {
    setIsModalOpen(false);
  };


  const duplicatePricingPlan = async (id: string,userId : string) => {
    const result = await duplicatePricingPlanData(id,userId)
    result.dealStartDate = formatDate(result.dealStartDate)
    result.dealEndDate = formatDate(result.dealEndDate)
    result.created = formatDate(result.created)

    let newArray = [...worksheets]
    let index = newArray.findIndex((worksheet) => worksheet.pricingPlanID === id)
    newArray.splice(index + 1, 0, result)
    setWorksheets(newArray)
  }

  const nextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const currentWorksheets = useMemo(() => {
    setNumPages(Math.ceil(worksheets.length / 10) > 0 ? Math.ceil(worksheets.length / 10) : 1)
    const firstPageIndex = (currentPage - 1) * 10
    const lastPageIndex = firstPageIndex + 10

    // Automatically move to previous page if current page has no more entries
    if (firstPageIndex === worksheets.length) {
      prevPage()
    }

    return worksheets.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, worksheets])

  // Load existing worksheet's global inputs page
  const loadWorksheet = (pricingPlanID: string) => {
    history.push('/pricing-plan/' + pricingPlanID + '/global-input')
  }

  useEffect(() => {
    return () => {
      if (msgTimeout !== null) {
        clearTimeout(msgTimeout);
      }
    };
  }, [msgTimeout]);
  
  const CheckinAndOut = async (value:string,userid:string,status: string) => {
    const requestBody={
      PricingPlanId:value,
      Userid:userid,    
    }

    const result = await CheckinCheckout(requestBody,status)
    if (result.success) {
      // Update the worksheet isCheckedIn status here if necessary
      // ...
      setMsg("Checked " + (status === "checkin" ? "in" : "out") + " successfully!");
      setShowMsg(true);
      setWorksheetIndex(value);
      if (msgTimeout !== null) clearTimeout(msgTimeout);
      const timeoutId = window.setTimeout(() => {
        setShowMsg(false);
        setWorksheetIndex('');
      }, 5000);
      setMsgTimeout(timeoutId);
    } else {
      setMsg("Deal is currently checked in on the other side");
      setShowMsg(true);
      setWorksheetIndex(value);
      if (msgTimeout !== null) clearTimeout(msgTimeout);
      const timeoutId = window.setTimeout(() => {
        setShowMsg(false);
        setWorksheetIndex('');
      }, 5000);
      setMsgTimeout(timeoutId);
    }
    return result;
  }


  return (
    <div className='landing-table-container'>
      <table className='landing-table body-text'>
        <thead>
          <tr className='landing-table-heading'>
            <th className='worksheet-name' onClick={() => headingSelected(0)}>
              Existing CCTs
              <img
                className='landing-table-triangle'
                src={triangle[0] ? YellowTriangle : WhiteTriangle}
                alt='Triangle depicting column as ascending or descending'
              />
            </th>
            <th className='engagement-name' onClick={() => headingSelected(1)}>
              Deal Name
              <img
                className='landing-table-triangle'
                src={triangle[1] ? YellowTriangle : WhiteTriangle}
                alt='Triangle depicting column as ascending or descending'
              />
            </th>
            <th className='client' onClick={() => headingSelected(2)}>
              Client Name
              <img
                className='landing-table-triangle'
                src={triangle[2] ? YellowTriangle : WhiteTriangle}
                alt='Triangle depicting column as ascending or descending'
              />
            </th>
            <th className='date' onClick={() => headingSelected(3)}>
              Deal Date
              <img
                className='landing-table-triangle'
                src={triangle[3] ? YellowTriangle : WhiteTriangle}
                alt='Triangle depicting column as ascending or descending'
              />
            </th>
            <th className='created' onClick={() => headingSelected(4)}>
              Created
              <img
                className='landing-table-triangle'
                src={triangle[4] ? YellowTriangle : WhiteTriangle}
                alt='Triangle depicting column as ascending or descending'
              />
            </th>
            <th className='delete'></th>
          </tr>
        </thead>
        <tbody>
          {currentWorksheets.map((worksheet) => (
            <tr key={worksheet.pricingPlanID}>
              <td onClick={() => loadWorksheet(worksheet.pricingPlanID)}>{worksheet.worksheetName}</td>
              <td>{worksheet.engagementName}</td>
              <td>{worksheet.client}</td>
              <td>{worksheet.dealStartDate + ' - ' + worksheet.dealEndDate}</td>
              <td>{worksheet.created}</td>
              <td>
                <div className='actions-container'>
                
                {/* Conditionally render the edit button */}
                {((worksheet.planStatus === 'Delegated' && worksheet.delegateAccess)|| (worksheet.planStatus === 'Owned' && worksheet.isDelegated )) &&(
                  <button className={`image-button edit-button`}>
                    <Tooltip
                        key={showMsg && Index === worksheet.pricingPlanID ? 'visible' : 'hidden'}
                        placement='top'
                        content={showMsg ? msg : (worksheet.isCheckedIn ? 'Click to Check-out' : 'Click to Check-in')}
                        {...(showMsg && Index === worksheet.pricingPlanID ? { visible: true } : {})}
                      >
                        <span
                          className="material-icons" style={{ color: worksheet.isCheckedIn ? '#0000ff' : '#008000' }} 
                          onClick={async () => {                            
                            const result = await CheckinAndOut(
                              worksheet.pricingPlanID,
                              userId,
                              worksheet.isCheckedIn ? "checkout" : "checkin"
                            );
                            if (result.success) {
                              worksheet.isCheckedIn = !worksheet.isCheckedIn;
                              setStatus(worksheet.isCheckedIn);                            
                            }                    
                          }}
                        >
                          edit
                        </span>
                      </Tooltip>
                </button>    
                )}
                {worksheet.planStatus === 'Owned' && (
                  <button
                    className='image-button delegate-button'
                    onClick={() => showDelegatePopup(worksheet.pricingPlanID, worksheet.isCheckedIn, worksheet.isDelegated, worksheet.delegateAccess, worksheet.planStatus)}
                  >
                    <Tooltip placement='top' content='Delegate'>
                      <span className='material-icons'>people_outline</span>
                    </Tooltip>
                  </button>
                )}
                  <span
                    className='material-icons duplicate-icon'
                    onClick={() => duplicatePricingPlan(worksheet.pricingPlanID,userId)}
                  >
                    <Tooltip placement='top' content='Duplicate'>
                      <span className='material-icons'>content_copy</span>
                    </Tooltip>
                  </span>
                  <button
                      className='image-button delete-button'
                      onClick={() => {
                          showDeletePopup(worksheet.pricingPlanID, worksheet.worksheetName);
                      }}
                      disabled={worksheet.planStatus === 'Delegated'}
                      style={{ 
                        pointerEvents: worksheet.isDelegated && worksheet.planStatus === 'Delegated'? 'none' : 'auto'
                        // ,cursor: worksheet.isDelegated ? 'not-allowed' : 'pointer'
                      }}
                    >
                      {worksheet.planStatus === 'Owned' && (
                          <Tooltip placement='top' content='Delete'>
                            <span className='material-icons'>delete_outline</span>
                          </Tooltip>
                        )}
                        {worksheet.planStatus === 'Delegated' && (
                          <span className='material-icons'>delete_outline</span>
                        )}
                    </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className='pagination-container'>
        <p className='body-text'>Showing 10 per page</p>
        <p className='body-text'>
          {currentPage} of {numPages}
        </p>
        <button className='pagination-prev-button image-button' onClick={prevPage}>
          <img src={PrevIcon} alt='Arrow pointing to the left' />
        </button>
        <button className='pagination-next-button image-button' onClick={nextPage}>
          <img src={NextIcon} alt='Arrow pointing to the right' />
        </button>
      </div>

      <DeleteModal
        worksheets={worksheets}
        setWorksheets={setWorksheets}
        deleteId={deleteId}
        deleteName={deleteName}
      ></DeleteModal>
      <DelegateModal 
      isOpen={isModalOpen} 
      delegateId={delegateId}
      onClose={hideDelegatePopup}
      confirmedUsers={confirmedUsers} 
      delegateAccess={delegateAccess}
      isCheckedIn={ischeckin}
      isDelegated={isDelegated}
      planStatus={planSetatus}
      worksheets={worksheets}
      setWorksheets={setWorksheets}
      /> 
    </div>
  )
}

export default LandingTable
